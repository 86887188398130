<template>
  <div id="home">
    <van-nav-bar
        title="会员中心"
        fixed
    />
    <div class="login">
      <div class="uc-top">
        <van-image :src="info.avatar" v-if="info.avatar"></van-image>
        <van-icon name="user-circle-o" v-else/>
      </div>
      <div class="ucenter">
        <van-grid :column-num="3" :border="border">
          <van-grid-item text="积分">
            <template #icon>
              <span> {{ info.total_integral }} </span>
            </template>
          </van-grid-item>
          <van-grid-item text="优惠券">
            <template #icon>
              <span> 0 </span>张
            </template>
          </van-grid-item>
          <van-grid-item text="卡包" @click.stop="goback('/recharge')">
            <template #icon>
              <span> {{ info.surplus_recharge }} </span>
            </template>
          </van-grid-item>
        </van-grid>
      </div>
      <van-list class="profile">
        <van-cell title="个人信息" icon="user-o" is-link to="/profile"></van-cell>
        <van-cell title="我的预约" icon="orders-o" is-link to="/order"></van-cell>
        <van-cell title="我的服务" icon="bill-o" is-link to="/service" v-if="info.mid>0"></van-cell>
        <van-cell title="修改密码" icon="setting-o" is-link to="/modify"></van-cell>
        <van-cell title="退出登陆" icon="wap-home-o" is-link @click.stop="logout"></van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {onMounted, reactive, toRefs} from 'vue';
import {httpget} from "@/libs/api";
import {useStore} from "vuex";
import { useRouter } from 'vue-router'

export default {
  name: 'Home',
  setup() {
    const store=useStore();
    const router = useRouter();
    const state = reactive({
      banner: require('@/assets/default/imgs/aboutus.jpg'),
      avatar: require('@/assets/default/imgs/avatar.png'),
      border:false,
      info:'',
    })
    const logout = () => {
      store.commit("setMemberInfo",null);
      store.commit('setFrontToken',null);
      router.push({path: '/home'}).catch(err => {err});
      return;
    }
    const getinfo = async () => {
      state.loading = true;
      let res = await httpget('/api/member/index');
      if(res.code===0){
        state.info = res.data;
        state.loading = false;
      }else{
        state.loading = false;
      }
    }
    onMounted(() => {
      getinfo();
    })

    return {
      ...toRefs(state),
      getinfo,
      logout
    };
  },
}
</script>
